import { __decorate } from "tslib";
import AdminModule from "@/store/module/admin/AdminModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import Vue from "vue";
import Component from "vue-class-component";
let RoundAdminAvatarMenu = class RoundAdminAvatarMenu extends Vue {
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get items() {
        return [
            {
                icon: "mdi-exit-to-app",
                href: "#",
                title: "Выход",
                click: () => {
                    this.$emit("logout");
                },
            },
        ];
    }
    get avatar() {
        if (this.admin && this.admin?.user.avatar) {
            return { src: `${IMAGE_SERVER_URL}/public/${this.admin.user.avatar}` };
        }
        else {
            return { src: require("@/assets/default-avatar.jpg") };
        }
    }
};
RoundAdminAvatarMenu = __decorate([
    Component({
        components: {},
    })
], RoundAdminAvatarMenu);
export default RoundAdminAvatarMenu;
