import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import AdminModule from "@/store/module/admin/AdminModule";
import ApproveDialog from "@/components/shared/Approve.vue";
import RoundAdminAvatarMenu from "@/components/admin/users/RoundAdminAvatarMenu.vue";
import { routePath } from "@/router/router-constants";
import HandbookModule from "@/store/module/shared/HandbookModule";
let AdminDashboard = class AdminDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.primaryDrawerModel = false;
        this.approve = {
            logout: false,
        };
    }
    get menu() {
        return [
            {
                title: "Члены РФСЛ",
                show: true,
                route: routePath.admin.USERS,
            },
            {
                title: "События",
                show: true,
                route: routePath.admin.EVENTS,
            },
            {
                title: "Сообщества",
                show: true,
                route: routePath.admin.GROUPS,
            },
        ];
    }
    logout() {
        this.$store.dispatch(AdminModule.types.actions.LOGOUT);
        this.$router.push(routePath.LOGIN);
    }
    async mounted() {
        await Promise.all([
            await this.$store.dispatch(HandbookModule.types.actions.GET_HANDBOOK),
            await this.$store.dispatch(AdminModule.types.actions.GET_ADMIN),
        ]);
    }
};
AdminDashboard = __decorate([
    Component({
        components: {
            ApproveDialog,
            RoundAdminAvatarMenu,
        },
    })
], AdminDashboard);
export default AdminDashboard;
